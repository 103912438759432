<template>
  <div class='border-t bg-white flex flex-col pt-6 h-48'>
    <div class='layout-container w-full bg-white flex flex-row justify-between px-4 lg:px-0'>
      <div class='grid grid-cols-2 gap-x-4 gap-y-2 lg:gap-x-12 lg:gap-y-4'>
        <router-link
          v-for='nav in eventConfigNavigations'
          :key='`nav-${nav.name}`'
          :to='{ name: nav.route }'
          class='footer-nav-link'
          :style='footerNavStyle'>
          {{ nav.name }}<img :src='nav.imageUrl' class='inline-block h-3'>
        </router-link>
        <div
          v-if='hasValidToken' 
          class='footer-nav-link text-left'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div>
        <img :src='eventConfigLogoImageUrl'
         class='h-4 lg:h-10 cursor-pointer mb-6'
         @click='goToHome'/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DefaultLayoutFooter',
  data () {
    return {
      publicPath: process.env.VUE_APP_URL,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken'
    ]),
    ...mapGetters('events', [
      'eventConfigNavigations',
      'eventConfigLogoImageUrl',
      'eventMainThemeColor'
    ]), 
    footerNavStyle () {
      return {
        '--color': this.eventMainThemeColor
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'logout'
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  }
}
</script>

<style lang='scss' scoped>
.footer-nav-link {
  @apply uppercase text-gray-700 text-xs block opacity-75;
}

.footer-nav-link.router-link-active {
  color: var(--color);
  @apply font-bold;
}

.footer-nav-link:hover {
  @apply opacity-100;
  color: var(--color);
}
</style>
